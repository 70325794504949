<template>
  <div class="hello">
    <!-- <div class="banner">
      <div class="margin bodywithe flex alignCenter home">
        <div class="flex">
          <div style="line-height: 30px; color: #fff; font-size: 20px"></div>
        </div>
        <div class="flex">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="list"
            :class="[
              currentIdx == index ? 'bordersbot' : '',
              currentCheckIdx == index ? 'bordersbot' : '',
            ]"
            @mouseenter="enter(index)"
            @mouseleave="leave()"
            @click="(currentCheckIdx = index), c5(currentCheckIdx)"
          >
            {{ item }}
          </div>
        </div>
        <div class="login flex">
          <div class="margLF" @click="dialogVisible = true">登录</div>
          <div class="margLF" @click="dialogVisible1 = true">注册</div>
        </div>
        <el-dialog
          title="手机号码登录"
          :visible.sync="dialogVisible"
          width="20%"
          style="z-index: 99999"
          :append-to-body="true"
          :before-close="handleClose"
          center
        >
          <el-form :model="form">
            <el-input placeholder="请输入手机号" v-model="form.input" clearable>
            </el-input>
            <el-form-item label="" prop="code">
              <div class="verify-wrapper flex">
                <el-input
                  type="text"
                  width="60%"
                  maxlength="6"
                  suffix-icon="el-icon-lock"
                  placeholder="请输入验证码"
                  v-model="form.code"
                />
                <el-button
                  class="btn-orange"
                  :disabled="disabled"
                  @click="getCode"
                  >{{ valiBtn }}</el-button
                >
              </div>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">关 闭</el-button>
            <el-button type="primary" @click="dialogVisible = false"
              >登 录</el-button
            >
          </span>
        </el-dialog>
        <el-dialog
          title="手机号码注册"
          :visible.sync="dialogVisible1"
          width="20%"
          style="z-index: 99999"
          :append-to-body="true"
          :before-close="handleClose"
          center
        >
          <el-form :model="form">
            <el-input placeholder="请输入手机号" v-model="form.input" clearable>
            </el-input>
            <el-form-item label="" prop="code">
              <div class="verify-wrapper flex">
                <el-input
                  type="text"
                  width="60%"
                  maxlength="6"
                  suffix-icon="el-icon-lock"
                  placeholder="请输入验证码"
                  v-model="form.code"
                />
                <el-button
                  class="btn-orange"
                  :disabled="disabled"
                  @click="getCode"
                  >{{ valiBtn }}</el-button
                >
              </div>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible1 = false">关 闭</el-button>
            <el-button type="primary" read-only @click="dialogVisible1 = false"
              >注 册</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div> -->
    <div id="shouye" class="banners">
      <img src="../images/banner.jpg" alt="" />
    </div>
    <div class="margin borders bodywithe">
      <!-- 关于我们 -->
      <div id="andme" class="bodywithe andme flex">
        <div class="andmeL">
          <div class="text1">关于我们</div>
          <div class="text2 fwb">ABOUT US</div>
          <div class="text3">
            <p>“蜻蜓到家”是一款集家庭保洁、保姆月嫂为一体的生活服务平台。</p>
            <p>
              该平台聚合微信公众号生态圈，用户只需扫码关注，即可享受与APP同等级别的服务。“蜻蜓到家”针对不同用户属性的特点和需求，分别设置客户端、服务端、指挥端等多个接入口。
            </p>
            <p>“客户端”重点功能包括家庭清洁、保姆、月嫂等；</p>
            <p>
              “指挥端”针对家政日常重点工作展开功能布局，订单指派、人员调度、售后服务等；
            </p>
            <p>“服务端”功能包括通知公告、订单接收、服务、订单处理等。</p>
          </div>
        </div>
        <div class="andmeR">
          <img class="p1" @click="c1()" src="../images/1.png" alt="" />
          <img class="p2" @click="c2()" src="../images/2.png" alt="" />
          <img class="p3" @click="c3()" src="../images/3.png" alt="" />
          <img class="p4" @click="c4()" src="../images/4.png" alt="" />
        </div>
      </div>
      <!-- 行业现状 -->
      <!-- <div id="hangye" class="bodywithe hangye flex">
      <div class="hangyeL">
        <div class="text1">行业现状</div>
        <div class="text2 fwb">Industry status</div>
        <div class="text3">
          <p>“新物业模式”加速到来！</p>
          <p class="pp">多数物业服务公司已经实 现物业模式转型升级，把 传统物业模式变为“互联网 ＋创新治理”的“新物业模 式”，该模式已经成为了物 业公司从管理转变服务的新思路。</p>
        </div>
      </div>
      <div class="hangyeR">
        <img src="../images/hangye.png" alt="">
      </div>
    </div> -->
      <!-- app 简介 -->
      <div
        id="jianjie"
        class="bodywithe jianjie flex"
        style="margin-top: 100px"
      >
        <div class="jianjieL">
          <div class="text1">APP简介</div>
          <div class="text2 fwb">introduce</div>
          <div class="tu3 flex">
            <div class="tubox">
              <img src="../images/r1.png" alt="" />
            </div>
            <div class="tubox marginLf">
              <img src="../images/r2.png" alt="" />
            </div>
            <div class="tubox marginLf">
              <img src="../images/r3.png" alt="" />
            </div>
          </div>
          <div class="wenzi3">
            <p>家庭清洁：省心下单、贴心上门、放心售后......</p>
            <p>做饭保姆：做饭做家务......</p>
            <p>年薪月嫂：宝宝舒心、妈妈放心......</p>
          </div>
          <div class="paRB">关于更多></div>
        </div>
        <div class="jianjieR">
          <img src="../images/ppso.png" alt="" />
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div id="huoban" class="huoban bodywithe">
        <div>
          <div class="text1">合作伙伴</div>
          <div class="text2">cooperative partner</div>
        </div>
        <div class="tu">
          <img src="../images/banner1.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <div id="body-bottom" class="body-bottom">
      <div class="bodywithe margin dibu flex alignCenter">
        <div class="marginLfb boxtu">
          <img src="../images/logo.png" alt="" />
        </div>
        <div class="marginLfb">关于我们</div>
        <div class="marginLfb">相关资讯</div>
        <div class="marginLfb">问题反馈</div>
        <div>版权声明</div>
      </div>
      <div class="shengming">
        <div class="bodywithe margin flex col col_marginT">
          <div>
            Copyright © 2019 杭州聚达物联科技有限公司&nbsp; 版权所有
          </div>
          <div>浙ICP备：2021005354号</div>
        </div>
        <div class="bodywithe margin flex col">
          <div>联系邮箱：linjiaxiaoda@126.com</div>
          <div>联系电话：0571-8687830</div>
        </div>
        <div class="bodywithe margin flex col">
          <div>浙公网安备：33010402004563号</div>
          <div>
            公司地址：浙江省杭州市江干区新塘路672号中豪国际商业中心4幢901室
          </div>
        </div>
      </div>
    </div>
    <div style="width: 400px; margin: 0 auto; padding: 20px 0">
      <a
        target="_blank"
        href=" "
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        "
        ><img src="" style="float: left" />
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          "
        >
          浙公网安备 33010402004563号 浙ICP备2021005354号
        </p></a
      >
    </div> -->
  </div>
</template>

<script>
export default {
  name: "qtdj",
  data() {
    return {
      list: ["首页", "关于我们", "产品介绍", "合作伙伴"],
      currentIdx: 0,
      currentCheckIdx: 0,
      dialogVisible: false,
      dialogVisible1: false,
      form: {
        input: "",
        code: "",
      },
      valiBtn: "获取验证码",
      disabled: false,
      time: 59,
    };
  },
  methods: {
    c0() {
      document.getElementById("shouye").scrollIntoView();
    },
    c1() {
      document.getElementById("hangye").scrollIntoView();
    },
    c2() {
      document.getElementById("jianjie").scrollIntoView();
    },
    c3() {
      document.getElementById("huoban").scrollIntoView();
    },
    c4() {
      document.getElementById("body-bottom").scrollIntoView();
    },
    c5(e) {
      if (e === 0) {
        document.getElementById("shouye").scrollIntoView();
      }
      if (e === 1) {
        document.getElementById("andme").scrollIntoView();
      }
      if (e === 2) {
        document.getElementById("hangye").scrollIntoView();
      }
      if (e === 3) {
        document.getElementById("jianjie").scrollIntoView();
      }
      if (e === 4) {
        document.getElementById("huoban").scrollIntoView();
      }
    },
    enter(idx) {
      console.log(idx);
      this.currentIdx = idx;
    },
    leave() {
      this.currentIdx = null;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
      this.form.input = "";
      this.form.code = "";
    },
    getCode() {
      const timer = setInterval(() => {
        if (this.time === 0) {
          clearTimeout(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
          this.time = 59;
        } else {
          this.disabled = true;
          this.valiBtn = this.time + "秒后重试";
          // eslint-disable-next-line no-const-assign
          this.time--;
          console.log(this.time);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="less">
/* .v-model {
  background-color: rgba(0,0,0,0);
} */
.verify-wrapper {
  margin-top: 40px;
  .btn-orange {
    margin-left: 10px;
    padding: 11px 20px;
  }
}
.banner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 80px;
  // background-color: #77B4FA;
  background: url("../images/banner-top2.png") no-repeat;
  background-size: 100% 100%;
  .home {
    position: relative;
    height: 100%;
    justify-content: space-between;
    .logoimg {
      width: 107px;
      height: 30px;
    }
    .list {
      height: 60px;
      line-height: 60px;
      margin-left: 40px;
      font-size: 18px;
    }
    .bordersbot {
      border-bottom: 4px solid #2a44a3;
    }
    .login {
      position: absolute;
      top: 0;
      right: -400px;
      height: 80px;
      line-height: 80px;
      .margLF {
        margin-right: 20px;
      }
    }
  }
}
.banners {
  margin-top: 80px;
  width: 100%;
  img {
    width: 100%;
  }
}
.borders {
  padding: 0 10px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding-bottom: 60px;
}
  .margin {
    margin: 0 500px;
  }
.andme {
  width: 920px;
  .andmeL {
    width: 470px;
  }
  .andmeR {
    margin-top: 60px;
    margin-left: 60px;
    width: 310px;
    width: 300px;
    position: relative;
    .p1 {
      position: absolute;
      top: 5px;
      left: 72px;
    }
    .p2 {
      position: absolute;
      top: 5px;
      left: 220px;
    }
    .p3 {
      position: absolute;
      top: 140px;
      left: -5px;
    }
    .p4 {
      position: absolute;
      top: 140px;
      left: 150px;
    }
    img {
      width: 147px;
      height: 168px;
    }
  }
}
.hangye {
  .hangyeL {
    width: 370px;
    .text3 {
      .pp {
        margin-top: 30px;
      }
    }
  }
  .hangyeR {
    margin-top: 60px;
    margin-left: 80px;
    img {
      width: 450px;
      // height: 220px;
    }
  }
}
.jianjie {
  .jianjieL {
    position: relative;
    width: 340px;
    .tu3 {
      margin-top: 60px;
      .tubox {
        width: 99px;
        height: 96px;
      }
      .marginLf {
        margin-left: 20px;
      }
    }
    .wenzi3 {
      margin-top: 60px;
      margin-bottom: 60px;
      p {
        color: #333333;
        font-size: 14px;
        line-height: 30px;
      }
    }
    .paRB {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 4px 8px;
      font-size: 14px;
      color: #fff;
      background-color: rgba(22, 155, 213, 1);
    }
  }
  .jianjieR {
    img {
      height: 460px;
    }
  }
}
.huoban {
  .tu {
    img {
      width: 900px;
    }
  }
}
.body-bottom {
  width: 100%;
  height: 220px;
  color: #fff;
  // background-color: rgba(0, 0, 0, 0.223529411764706);
  background: url("../images/banner-bot.png") no-repeat;
  background-size: 100% 100%;
  .dibu {
    height: 80px;
    // border-bottom: 1px solid #333;
    font-size: 14px;
    // color: #333333;
    .marginLfb {
      margin-right: 20px;
    }
    .boxtu {
      img {
        width: 65px;
        height: 62px;
      }
    }
  }
  .shengming {
    .col {
      line-height: 30px;
      div {
        width: 50%;
        font-size: 13px;
        // color: #333333;
      }
    }
    .col_marginT {
      margin-top: 20px;
    }
  }
}
</style>